<template>
    <div class="p-2">
        
        <div class="">
            <router-link to="/bussiness/shop" class="h4 mb-0 text-white d-flex align-items-center" style="">
                <i class="bx bx-chevron-left" style="font-size: 20px;color: #FFAD32;"></i>
                Back
            </router-link>
        </div>

        <div class="h-100 flex-fill d-flex flex-column">
            <h3 class="text-center font-weight-bolder text-white mt-2">Add Product</h3>

            <form action="" id="frm">
                <div class="mt-3">
                    <label class="h3 text-white">Product Name</label>
                    <div class="input-group">
                        <span class="input-group-text"></span>
                        <input class="form-control" placeholder="Product Name" name='inp[pr_name]' v-model="input_data.pr_name">
                    </div>
                </div>

                <div class="mt-3">
                    <label class="h3 text-white">Details</label>
                    <div class="input-group">
                        <span class="input-group-text"></span>
                        <textarea class="form-control" placeholder="Details"  name='inp[pr_details]' v-model="input_data.pr_details"></textarea>
                    </div>
                </div>

                <div class="mt-3">
                    <label class="h3 text-white">Price</label>
                    <div class="input-group">
                        <span class="input-group-text"></span>
                        <input class="form-control" placeholder="Price" name='inp[pr_price]' v-model="input_data.pr_price">
                    </div>
                </div>

                <div class="mt-3">
                    <label class="h3 text-white">Product Picture</label>
                    <div class="input-group">
                        <span class="input-group-text"></span>
                        <input class="form-control" placeholder="Name" type="file" name="pr_picture" accept="image/*">
                    </div>
                </div>

                <div class="mt-3">
                    <label class="h3 text-white">Hyperlink</label>
                    <div class="input-group">
                        <span class="input-group-text"></span>
                        <input class="form-control" placeholder="Name" name='inp[pr_hyperlink]' v-model="input_data.pr_hyperlink">
                    </div>
                </div>
            </form>

            <div class="btn mt-3"
                style="padding:.75em 3em;margin-top:.5em;gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 15px;">
                <div class="h3 text-white mb-0" @click="onSubmit()">Submit</div>
            </div>
        </div>
    </div>
</template>

<script>

import store from '@/store'
import Cookies from 'universal-cookie'
let cookies = new Cookies()

export default {
    computed: {
        user(){
            return store.state.auth.USER
        },
        store_data(){
            return this.user?.store_data
        },
    },
    methods: {
        onSubmit() {
            let formData = new FormData($('#frm')[0])
            formData.append('inp[user_id]', cookies.get('token'))
            formData.append('inp[store_id]', this.store_data?.store_id)
            if(this.id){
                formData.append('id', this.id)
            }

            store.dispatch('store_api/SaveProduct', formData).then(() => {
                this.$router.push('/bussiness/shop')
            })
        }
    },
    async mounted(){
        if(this.id){
            this.input_data = await store.dispatch('store_api/GetProduct', this.id)
            console.log(this.input_data)
        }
    },
    data(){
        return {
            input_data: {},
            id: this.$route.params.id
        }
    }
}

</script>

<style>
.auth-page .btn {
    border-radius: 20px;
}

input:focus {
    color: white !important;
}

.input-group span {
    border: unset;
    border-radius: unset;
    border-bottom: 1px solid white;
    font-size: 24px;
    color: #1F9BCD;
    background-color: transparent;
}

.input-group input, .input-group textarea {
    margin-left: 0;
    padding-left: 0;
    border: unset;
    border-radius: unset;
    border-bottom: 1px solid white;
    height: 50px;
    color: white;
    background-color: transparent !important;
}
</style>